@import "../template/mixin_templates";
@import 'https://fonts.googleapis.com/css?family=Open+Sans:300,700';

/* Variables */
$largeur_fenetre_grand: 1360px;
$largeur_contenu: $largeur_fenetre_grand - 310px;

/*$small-screen: 640px;
$medium-screen: 1024px;
$large-screen: 1200px;
$xlarge-screen: 1440px;*/

/***************************************************/
/******************** ANIMATIONS *******************/
/***************************************************/

/***************************************************/
/********************* GENERAL *********************/
/***************************************************/

body, html {
  font-size: 16px;
  font-family: 'Open Sans', 'Arial', sans-serif;
  font-weight: 300;
  margin: 0;
  box-sizing: border-box;
}

strong, b {
  font-weight: 700;
}

*, *:before, *:after {
  box-sizing: inherit;
}

@include titreBaseSize();

h1, .h1 {
  font-size: 21px;
  font-weight: bold;
}

h2, .h2,
h1.title_section, .h1.title_section {
  margin: 15px 0;
  font-weight: bold;
  font-size: 24px;
  padding-bottom: 20px;
  position: relative;
  display: block;
  border-bottom: 2px solid rgba(0, 0, 0, .1);
  line-height: 30px;
  &::before {
    content: "";
    left: 0;
    position: absolute;
    top: 100%;
    width: 43px;
    display: block;
    height: 2px;
  }
}

a {
  text-decoration: none;
  img {
    border: none;
  }
}

img:not(.img-logo) {
  max-width: 100%;
  height: auto !important;
}

ul {
  padding: 0;
  list-style: none;
}

iframe {
  max-width: 100%;
}

table {
  position: relative;
  table-layout: fixed;
  max-width: 100% !important;
}

.preview .bx-wrapper {
  margin: 0 auto 20px;
}

.clearfix, .clear {
  clear: both;
}

.button, .bouton, .produits .mask .zoom {
  letter-spacing: 1px;
  width: auto;
  display: inline-block;
  padding: 11px 13px;
  font-size: 14px;
  font-weight: 700;
  border: 0 solid;
  transition: all .2s linear;
  margin: 10px 10px;
}

/* Conteneur principal */
#main-conteneur {
  width: $largeur_fenetre_grand;
  margin: 0 auto;
  position: relative;
  padding: 0;
  //z-index: 1;
  box-sizing: border-box;
  min-height: 100%;
  &::after {
    content: '';
    display: block;
    clear: both;
  }
}

.theme .wrapper-content, footer {
  width: $largeur_contenu;
  margin: 0;
  float: right;
  position: relative;
  padding: 0;
  //z-index: 1;
  box-sizing: border-box;
}

#wrapper.content_p, footer {
  padding: 0 20px;
}

#wrapper.content_p {
  padding-bottom: 60px;
  padding-left: 30px;
  &::after {
    content: '';
    clear: both;
    display: block;
  }
}

.wrapper-content ul {
  list-style-type: disc;
  list-style-position: inside;
}

.cmonsite-index #wrapper > div:nth-of-type(1):not(.accueil_diaporama_template) {
  padding-top: 30px;
}

.cmonsite-index .accueil_diaporama_template ~ #wrapper > div:nth-of-type(1) {
  padding-top: 0;
}

.content_p, .wrapper-content {
  position: relative;
}

/* site en deux colonnes */
aside.sidebar {
  display: none;
}

/* fil d'Arianne */
.arianne {
  margin: 0 auto 10px;
  font-size: 0;
  z-index: 2;
  position: relative;
  text-align: center;
  li {
    display: inline-block;
    font-size: 13px;
    float: none;
    &:not(:first-child)::before {
      content: '\f0a9';
      display: inline-block;
      margin: 0 10px;
      font-family: fontAwesome, sans-serif;
    }
  }
}

/**************************************************/
/********************* HEADER *********************/
/**************************************************/

header {
  padding: 0;
  z-index: 100;
  top: 0;
  width: 310px;
  box-sizing: border-box;
  position: relative;
  float: left;
}

.logo {
  display: inline-block;
}

.logo img {
  max-height: 155px;
  height: 100px;
  max-width: 100%;
}

body.cmonsite-panier-3 #main-conteneur #blocContent #headercontent {
  height: 240px;
  .template-panier.template-panier-3 {
    position: absolute;
    top: auto;
    bottom: 0;
    right: 0;
    left: 0;
  }
}

#headercontent {
  text-align: center;
  height: 155px;
}

.slogan {
  text-align: center;
  margin: 0;
}

.btn-search {
  margin: 0 !important;
}

#bloc-fil-title {
  padding: 31px 25px 31px 25px;
  margin: 0 0 20px -30px;
  text-align: center;
  box-sizing: content-box;
  width: 100%;
  position: relative;
  min-height: 93px;
  &::before {
    content: '';
    display: block;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
  }
  .title_section {
    display: block !important;
    background-color: transparent;
  }
}

h2.title_section, .titre_page h2,
.h2.title_section, .titre_page .h2,
h1.title_section, .titre_page h1,
.h1.title_section, .titre_page .h1 {
  width: 100%;
  text-transform: uppercase;
  border-width: 0;
  font-size: 30px;
  font-weight: bold;
  padding-bottom: 5px;
  border-bottom: none;
  &::before {
    display: none;
  }
}

.cmonsite-panier-3 #main-conteneur #blocContent {
  width: auto;

  #headercontent {
    height: auto;
  }

  .template-panier-3 {
    width: 275px;
    height: 40px;
    position: relative;
    z-index: 1001;
    margin: 20px auto;
    float: none;
    top: 0;
    border: none;

    .bloc-connexion-panier #panier #paniercontent {
      width: auto;
      min-width: 300px;
      border: 1px solid;
      display: none;
      top: 100%;
      right: -13px;
      position: absolute;
    }
  }
}

/************************************************/
/********************* MENU *********************/
/************************************************/

#nav-principal {
  position: relative;
  z-index: 2;
  width: 100%;
  padding: 0;
  margin: auto;
  > ul {
    font-size: 0;
    ul {
      position: absolute;
      top: 0;
      margin-top: -1px;
      left: 100%;
      z-index: 1;
      width: 100%;
      max-width: 210px;
      transition: transform .2s ease-in-out 0s;
      transform-origin: left center;
      transform: scaleX(0);

      li {
        display: block;
        border-left: 1px solid;
        overflow: hidden;
        &:hover, &.sousmenu_ouvert {
          overflow: visible;
        }
      }
      a {
        padding-left: 20px;
      }
      ul {
        left: 100%;
      }
    }
  }
  li {
    display: block;
    font-size: 1rem;
    position: relative;
    width: 100%;
    border-width: 1px 1px 1px 0;
    border-style: solid solid solid none;
    min-height: 64px;
    transition: all .2s linear;
    box-sizing: border-box;
    margin-top: -1px;
    li:first-child {
      margin-top: 0;
    }
    &:last-child {
      min-height: 64px;
    }

    &:hover >, a:focus + {
      ul {
        transform: scaleX(1);
      }
    }
  }
  li a {
    width: 100%;
    display: block;
    padding: 20px 20px 19px;
    line-height: initial;
    font-size: 16px;
    transition: all .2s linear;
    box-sizing: border-box;
    min-height: 62px;
    //position:relative;
  }
  > ul > li {
    //Si l'utilisateur a mis des pictos c'est eux qui ont le style
    > a {
      padding-left: 27px;
      border-left: 62px solid transparent;
    }
    &.has_picto > a > span > i {
      height: 63px;
      position: absolute;
      width: 62px;
      line-height: 63px;
      margin-right: 27px;
      display: inline-block;
      float: left;
      font-size: 25px;
      font-family: fontAwesome, sans-serif;
      text-align: center;
      transition: all .2s linear;
      left: 0;
      top: 50%;
      margin-top: -32px;
    }
    //Sinon on rajoute des pictos par défaut
    &:not(.has_picto) {
      > a::before {
        content: '';
        height: 63px;
        position: absolute;
        left: 0;
        width: 62px;
        line-height: 63px;
        margin-right: 27px;
        display: inline-block;
        float: left;
        font-size: 25px;
        font-family: fontAwesome, sans-serif;
        text-align: center;
        transition: all .2s linear;
        top: 50%;
        margin-top: -32px;
      }
      //On rajoute des pictos par défaut
      //Pour les pages
      &[class*="p-"] > a::before {
        content: '\f15c';
      }
      //Pour les produits
      &[class*="b-"] > a::before {
        content: '\f07a';
      }
      //Pour les albums
      &[class*="a-"] > a::before {
        content: '\f03e';
      }
      &.p-accueil > a::before {
        content: '\f015';
      }
      &.p-search > a::before {
        content: '\f002';
      }
      &.p-blog > a::before {
        content: '\f1ea';
      }
      &.p-livreor > a::before {
        content: '\f075';
      }
      &.p-contact > a::before {
        content: '\f0e0';
      }
    }
    //Flèche after au survol de l'objet toujours présente
    > a::after {
      content: "";
      border-top: 6px solid transparent;
      border-left: 12px solid transparent;
      border-bottom: 6px solid transparent;
      border-left: 12px solid;
      position: absolute;
      width: 6px;
      height: 12px;
      left: 57px;
      top: 50%;
      opacity: 0;
      transform: translate(0, -50%);
      transition: all .2s linear;
      z-index: 10;
    }

    &:hover > a, > a:focus, &.actif > a {
      padding-left: 35px;
      &::after {
        opacity: 1;
        left: 62px;
      }
    }

  }
}

/****************************************************/
/********************* PRODUITS *********************/
/****************************************************/

/*Général*/
.template_boutique_all {
  .produits {
    position: relative;
    z-index: 1;
    overflow: hidden;
    transition: background .2s linear;
    padding-bottom: 15px;
    margin-bottom: 15px;
    .remise {
      position: absolute;
      z-index: 100;
      left: auto;
      right: 0;
      margin-top: 0;
      padding: 10px;
      font-weight: bold;
    }
    .nomprod {
      padding: 20px 0;
      font-size: 16px;
      text-transform: uppercase;
      font-weight: 300;
      text-align: center;
    }
    .productImageWrap {
      //width: 100%;
      overflow: visible;
      position: relative;
      .zoom {
        position: absolute;
        height: 33px;
        width: 45px;
        line-height: 33px;
        text-align: center;
        transition: all .2s linear;
        padding: 0;
        opacity: 0;
        top: 0;
        left: 50%;
        z-index: 10;
        display: block;
        margin: 0;
        &::before {
          line-height: 32px;
          font-size: 15px;
          bottom: 0;
          top: auto;
          text-align: center;
        }
      }
    }
    .attr, .attr select {
      //height: 21px !important; //Permet de caller les remises
      margin: 0 auto 5px;
      display: block;
      right: auto;
      float: none;
      position: static;
    }
    .mask {
      position: absolute;
      width: 100%;
      a {
        opacity: 0;
      }
    }
    .oldprix {
      text-decoration: line-through;
      opacity: .7;
      margin: 0;
      position: static;
    }
    .prixprod {
      margin: 0;
    }
    .prix {
      border-width: 1px 0 1px 0;
      border-style: solid none solid none;
      margin: 5px 0 20px;
      text-align: center;
      width: 100%;
      height: 60px;
      padding: 10px 5px;
      line-height: 20px;
      box-sizing: border-box;
      .oldprix, .prixprod {
        display: block;
        text-align: center;
        margin: 0;
        padding: 0;
        width: 100%;
      }
    }
    .desc {
      margin-bottom: 20px;
    }
    .remise ~ .prix, .remise ~ .productContentWrap .prix {
      width: 50%;
      border-right: 1px solid;
      height: 60px !important;
      line-height: normal;
      padding: 5px;
    }
    .remise {
      position: absolute;
      right: 0;
      left: auto;
      bottom: 61px;
      width: 50%;
      height: 60px;
      line-height: 60px;
      padding: 0;
      text-align: center;
      z-index: 1;
      span {
        font-size: 20px;
      }
    }
    .container-etiquette {

    }
    .addbasket, .hors-stock {
      position: absolute;
      width: 0 !important;
      height: 0 !important;
      background: transparent !important;
      color: transparent !important;
      top: 0;
      left: 50%;
      padding: 0;
      margin: 0 0 0 -47px;
      float: none;
      opacity: 1;
      display: block;
      transition: padding-top .2s linear, top .2s linear;
      z-index: 10;
    }
    .mask, .inside-addbasket, .inside-hors-stock {
      position: absolute;
      height: 33px;
      width: 45px;
      line-height: 33px;
      text-align: center;
      top: 0;
      left: 50%;
      opacity: 0;
      margin-left: 2px;
      transition: opacity .2s linear, background-color .2s linear, color .2s linear, top .2s linear;
      padding: 0;
    }
    .inside-addbasket, .inside-hors-stock {
      left: 0;
    }
    .mask .zoom {
      left: 0;
    }

    .inside-hors-stock {
      opacity: 0;
      transition: opacity .2s linear, top .2s linear;
      &::before {
        width: 45px;
        text-align: center;
        height: 33px;
        font-size: 16px;
        line-height: 33px;
        position: absolute;
        left: 0;
        top: 0;
        text-indent: 0;
        content: "\f00d";
        font-family: fontAwesome, sans-serif;
        color: #fff;
        background: red;
        opacity: .5;
      }
    }
    .inside-addbasket, .inside-hors-stock {
      position: absolute;
      border-width: 0;
      width: 45px;
      height: 33px;
      text-indent: -9999px;
      overflow: hidden;
      z-index: 10;
      right: 0;
      top: auto;
      bottom: -33px;
      margin: 0;
      i {
        width: 45px;
        text-align: center;
        height: 33px;
        line-height: 33px;
        position: absolute;
        left: 0;
        top: 0;
        text-indent: 0;
      }
    }

    &:hover, &:focus {
      .mask {
        opacity: 1;
        top: 50%;
      }
      .addbasket, .hors-stock {
        padding-top: 50%;
      }
      .inside-addbasket, .inside-hors-stock {
        opacity: 1;
      }
      .productImageWrap .zoom {
        opacity: 1;
        top: 50%;
      }
      .productImageWrap .mask .zoom {
        top: 0;
      }
    }

    .note-produit {
      text-align: center;
    }
  }
}

.template_boutique {
  &.template_boutique_1, &.template_boutique_2, &.template_boutique_5 {

    .product_have_promotion {
      > .remise {
        display: none !important;
      }

      > .productContentWrap {

        .prix {
          width: 100% !important;
          .prix__block {
            width: 50% !important;
            display: inline-block !important;
            float: left !important;
          }
        }

        .remise {
          width: 50% !important;
          display: inline-block !important;
          position: relative !important;
          margin: 0 !important;
          top: -6px !important;
          bottom: 0 !important;
          left: 6px !important;
          right: -8px !important;
          float: left !important;
        }
      }

    }
  }

  &.template_boutique_3 {
    .produits {
      padding-bottom: 10px;
      .productImageWrap {
        z-index: auto;
      }
      .remise {
        top: 10px;
        left: 10px;
        right: auto;
        bottom: auto;
        padding: 12px 5px;
        width: auto;
        height: auto;
        line-height: normal;
        ~ .productContentWrap .prix {
          width: 100%;
          border-right-width: 0;
        }
      }
      &.is_etiquette .nomprod {
        margin-top: 0;
      }
      .container-etiquette .produit_etiquette {
        position: absolute;
      }
      .addbasket, .hors-stock {
        position: relative !important;
        width: auto !important;
        height: auto !important;
        left: 0 !important;
        display: inline-block !important;
        margin: 0 !important;
        padding: 0 !important;

        .inside-addbasket {
          width: auto !important;
          height: auto !important;
          position: relative !important;
          text-indent: unset !important;
          bottom: unset !important;
          opacity: 1 !important;
          top: unset !important;
          left: unset !important;
          right: unset !important;
          padding-right: 15px !important;

          .fa {
            position: relative !important;
            width: 35px;
          }
        }
      }
      &:hover {
        .addbasket, .hors-stock {
          padding-top: 15%;
        }
      }
    }
  }

  &.template_boutique_4 {
    .produits {
      .remise {
        top: 0;
        left: 0;
        right: auto;
        bottom: auto;
        padding: 5px;
        width: auto;
        height: auto;
        line-height: normal;

        ~ .prix,
        ~ .productContentWrap .prix {
          width: 100%;
          border-right: none;
        }
      }
      .prix {
        width: 100%;
        border-right-width: 0;
        margin-top: 20px;
        .oldprix {
          padding-top: 5px;
        }
      }
      .attr {
        padding: 0;
      }
      .attr select {
        position: static;
      }
      .container-etiquette {
        position: absolute;
        top: 0;
        right: 0;
        margin-top: 0;
      }
      .produit_etiquette {
        h3, .h3 {
          position: relative;
        }
      }
    }
  }

  &.template_boutique_3, &.template_boutique_4 {
    .produits {
      .productContentWrap {
        position: static;
      }
    }
  }

  &.template_boutique_5 {
    .produits {
      .zoom {
        height: 33px !important; //Passer au dessus du js qui lui met une taille
      }
      .container-etiquette {
        position: absolute;
      }
      .productImageWrap {
        min-height: 0 !important;
      }
      .produit_etiquette::before {
        right: auto;
        left: -100%;
        padding: 0 10px 0 35px;
      }
      .produit_etiquette:hover::before, .produit_etiquette:focus::before {
        right: auto;
        left: 50%;
        opacity: 1;
      }
      .prix {
        clear: both;
        float: left;
        width: 100%;
      }
      .remise ~ .productContentWrap .prix {
        width: 50%;
      }
      .attr {
        padding: 0;
        clear: both;
        float: none;
      }
      .attr select {
        position: static;
      }
      .productImageWrap {
        min-height: 0;
        height: auto;
      }
      .addbasket, .hors-stock {
        left: 25%;
        margin-top: 5px;
        margin-left: -42px;
      }
      &:hover {
        .addbasket, .hors-stock {
          padding-top: 25%;
        }
      }
    }
  }
}

/* Produit etiquette */

.produit_etiquette, .produit_etiquette h3, .produit_etiquette .h3 {
  width: 50px;
  height: 50px;
  line-height: 50px;
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 50%;
  font-size: 20px;
  text-align: center;
  padding: 0;
  overflow: hidden;
  max-width: 100%;
  &:hover, &:focus {
    overflow: visible;
  }
  i {
    text-indent: 0;
    display: inline;
    position: absolute;
    left: 0;
    right: 0;
    height: 50px;
    margin: 0;

  }
}

.produit_etiquette {
  width: 50px !important;
  height: 50px !important;
  h3, .h3 {
    text-indent: -9999px;
  }
  &::before {
    content: attr(data-title);
    display: block;
    position: absolute;
    opacity: 0;
    right: -100%;
    font-size: 13px;
    font-weight: bold;
    line-height: 50px;
    height: 50px;
    padding: 0 35px 0 10px;
    top: 0;
    white-space: nowrap;
    transition: all .2s linear;
  }
  &:hover::before, &:focus::before {
    right: 50%;
    opacity: 1;
  }
  &::after {
    border-width: 0;
  }
  i::before {
    content: "\f129";
    font-family: "fontAwesome", sans-serif;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 50px;
    margin-left: .2em;
  }
}

.template_boutique_accueil {
  &.accueil_boutique_template_1 {
    $largeurFleches: 45px;
    $margin: 20px;
    $nbrProduit: 4;
    @include produitAccueilTaille($largeurFleches, $nbrProduit, $margin);
    margin-top: 0;
    .produits {
      margin-right: 20px;
    }
    .list-produits {
      text-align: left;
    }
    .bx-wrapper {
      margin: 0;
      .bx-controls-direction a {
        width: 35px;
        height: 35px;
        right: -45px;
        left: auto;
        line-height: 35px;
        &.bx-prev {
          top: 45px;
        }
        &.bx-next {
          top: 0;
        }
      }
    }
  }
}

/*********************************************************/
/********************* FICHE PRODUIT *********************/
/*********************************************************/

/* miniatures photos */
#fiche-produit .previews .previous, .previews .next {
  width: auto;
}

#fiche-produit .wrap-images .previews .list-previews {
  width: 71%;
}

#fiche-produit{
  .declinaison-attribute-group, .product_options-group {
    select[multiple] {
      min-width: 99px;
      height: 60px;
    }
  }
}


/* Permet de bien afficher le hover des images fiches produits pour le zoom */
.content_p .zoomPupImgWrapper > img, .content_p .zoomWrapperImage > img {
  max-width: none;
}

.triangle-ficheprod-second,
.triangle-ficheprod {
  position: absolute;
  right: 0;
  top: 0;
}

.template_fiche_produit_2 #fiche-produit .wrap-images .triangle-ficheprod {
  top: 0;
  right: 0;
}

.template_fiche_produit {
  #fiche-produit {
    .produit_etiquette {
      position: absolute;
      z-index: 101;
    }
    .hors-stock {
      display: block;
      float: none;
      height: auto;
      margin: 15px auto;
      box-sizing: border-box;
    }
    .top_fiche {
      margin-bottom: 30px;
      margin-top: 30px;
      border: 1px solid;
      &.is_catalogue {
        .bloc-quantite {
          display: none;
        }
      }
      &.show_price_none {
        .bloc-quantite, .ajouter-panier {
          display: none;
        }
      }
      .button {
        display: inline-block;
      }
    }
    .zoom-img-position {
      margin: 0;
    }
    .previews {
      position: relative;
    }
    .previews .previous, .previews .next {
      height: 35px;
      line-height: 35px;
      width: 35px;
      display: block;
      text-indent: 0;
      right: 0;
      font-size: 20px;
      text-align: center;
      transition: all .2s linear;
      transform: translateY(0);
    }
    .previews .previous {
      top: auto;
      bottom: 0;
      left: auto;
    }
    .previews .previous i::before {
      content: '\f104';
    }
    .previews .next {
      top: 0;
    }
    .previews .next i::before {
      content: '\f105';
    }
    .wrap-images {
      padding: 5px;
      margin-right: 0;
      .grande-image, .grande-image > a {
        height: auto;
      }

      .remise-produit {
        display: block;
        font-size: 20px;
        right: 28px;

      }
    }
    .wrap-description {
      float: right;
      border-left: 1px solid;
      padding: 38px 32px 0;
      p {
        font-size: 15px;
      }
      .ajouter-panier {
        padding: 20px 32px;
        box-sizing: content-box;
        position: relative;
        font-size: 0;
        text-align: center;

        /* Centrer les blocs dans cette div */
        vertical-align: middle;
        display: block;
        &::before {
          content: "";
          display: inline-block;
          vertical-align: middle;
          height: 100%;
        }
        &::after {
          content: "";
          display: block;
          clear: both;
          width: 100%;
        }
        > div, > p, > a {
          display: inline-block;
          vertical-align: middle;
          font-size: 1rem;
          float: none;
          overflow: visible;
          width: auto;
        }
        /* fin du centrage */

        .prix {
          margin: 0;
          height: auto;
          line-height: initial;
          padding-left: 15px;
          .oldprix, .prixprod {
            line-height: 40px;
            color: inherit;
            span {
              display: inline;
            }
          }
          .prixprod {
            position: relative;
            top: 3px;
          }
          span {
            display: inline-block;
            line-height: initial;
            width: auto;
          }
        }
        .triangle-ficheprod-second,
        .triangle-ficheprod {
          position: relative;
          right: auto;
          left: auto;
          display: inline-block;
          vertical-align: middle;
          border-right: 1px solid;
        }
        .remise-produit {
          position: relative;
          right: auto;
          font-size: 30px;
          padding: 5px 15px;
          display: inline-block;
          height: auto;
          width: auto;
          border-radius: 0;
        }
        .addbasket {
          float: right;
          height: auto;
          border-width: 0;
          padding: 13px;
          margin-top: 0;
          transition: all .4s linear;
          text-align: center;
        }
      }
      .pro-pannier {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        width: calc(100% + 64px);
        margin-left: -32px;
      }
      .bloc-quantite {
        order: 1;
        flex: 0 0 auto;
        box-sizing: border-box;
      }
      .block-price {
        order: 2;
        flex: 1 1 50%;
        box-sizing: border-box;
      }
      .block-addbasket {
        order: 3;
        flex: 1 1 35%;
        box-sizing: border-box;
      }
      .pro-pannier ~ p:not(.details-ficheprod) {
        float: left;
        width: calc(100% - 210px);
      }
      .description {
        clear: both;
      }
      .details-ficheprod {
        float: right;
        width: auto;
      }
      .bloc-quantite {
        background: rgba(0, 0, 0, .05);
        padding: 20px 32px;
        text-transform: uppercase;
        width: 100%;
        margin: 0;
        text-align: center;
        label {
          font-size: 18px;
          margin-right: 15px;
        }
        .notification_product {
          margin: 15px auto 0;
        }
        .notification_product:empty {
          display: none;
        }
      }
      .sociaux {
        clear: both;
        border-width: 0;
      }
      .prix {
        border-width: 0;
      }
      h3 span, .h3 span {
        font-size: 21px;
        font-weight: bold;
        text-transform: uppercase;
      }
      .declinaison-attribute-group, .product_options-group {
        margin-bottom: 15px;
      }
      .declinaison {
        padding: 0 35px;
        .declinaison-attribute-group__item, .product_options-group__item {
          display: inline-block;
          vertical-align: middle;
        }
        .radio_color {
          box-shadow: none;
          transition: all .4s linear;
          border-color: rgba(0, 0, 0, .05);

          &:hover, &:focus, &.radio-color-checked {
            border-color: rgba(0, 0, 0, .2);
          }
        }
        .radio_unique {
          box-shadow: none;
          margin-left: 0;
          border: none;
          transition: all .4s linear;
          margin-top: 10px;
        }
        .radio_unique, .radio_color {
          margin: 5px;
        }
      }
      .declinaison label {
        margin-bottom: 0;
        &.radio_unique {
          float: none;
        }
        &:first-child {
          display: inline-block;
        }
      }
      .container-etiquette {
        position: static;
      }
    }
    .h1.fiche-titre-produit {
      display: none;
    }
    .brand {
      border-bottom: none !important;
      margin: 0 0 10px;
      &::before {
        display: none;
      }
    }
    .message-wrap, #addcomment > div {
      box-shadow: none;
      border-color: rgba(0, 0, 0, .1);
    }
  }

  &.template_fiche_produit_1 #fiche-produit {
    .wrap-images {
      .remise-produit {
        display: none;
      }
    }
    .previews {
      .previous {
        top: 40px;
        transform: translateY(0);
      }
      .next {
        top: 0;
        transform: translateY(0);
      }
    }
    .triangle-ficheprod-second {
      display: inline-block !important;
    }
  }
  &.template_fiche_produit_2 #fiche-produit {
    #savoirplus {
      //width:70%;
    }
    .wrap-description {
      .pro-pannier {
        width: calc(100% + 42px);
        margin-left: -21px;
      }
      .declinaison {
        padding: 0;
      }
      .bloc-quantite {
        padding: 20px 10px;
      }
    }
    .produit_etiquette {
      right: auto;
      left: 10px;
      top: 10px;
    }
    .top_fiche .wrap-description .bloc-quantite {
      label {
        font-size: 15px;
        margin-right: 5px;
      }
    }
    .wrap-images {
      .remise-produit {
        top: 0;
        right: 0;
        left: auto;
        bottom: auto;
        width: auto;
        border-radius: 0;
      }
    }
    .top_fiche .wrap-description {
      overflow: hidden;
      padding: 38px 21px 0;
      margin: 0 0 0 -21px;
      position: relative;
      .ajouter-panier {
        .prix {
          padding: 0;
        }
        .addbasket {
          float: none;
          margin: 10px;
          font-size: 1.1rem;
        }
        .block-price {
          padding-bottom: 0;
        }
        .pro-pannier ~ p:not(.details-ficheprod) {
          float: none;
          width: 100%;
        }
        .button {
          display: inline-block;
          margin: 15px auto 0;
          &.btn-details {
            margin-top: 0;
          }
        }
        .details-ficheprod {
          float: none;
          width: 100%;
          text-align: center;
        }
        .pro-pannier {
          .row {
            margin: 0;
          }
        }
      }
      .remise-produit {
        position: relative;
        right: auto;
        //background-color:transparent;
        font-size: 30px;
        padding: 5px 15px;
        display: inline-block;
        height: auto;
        width: auto;
        top: 4px;
        border-radius: 0;
      }
      .produit_etiquette::before {
        left: -100%;
        padding: 0 10px 0 35px;
        right: auto;
      }
      .produit_etiquette:hover::before, .produit_etiquette:focus::before {
        left: 50%;
        right: auto;
        opacity: 1;
      }
      #produit-comment {
        > h4, > .h4 {
          text-align: center;
        }
        .pull-left, .pull-right {
          text-align: center;
          width: 100%;
        }
        #addcomment {
          max-width: 100%;
        }
      }
    }
  }

  &.template_fiche_produit_3 #fiche-produit {
    .top_fiche {
      .h1.fiche-titre-produit {
        display: none;
      }
      [itemprop="brand"] {
        padding: 0 15px;
      }
    }
    .produit_etiquette {
      right: auto;
      left: 0;
    }
    .wrap-description {
      width: 100%;
      float: none;
      height: auto;
      margin-bottom: 20px;
      .bloc-quantite {
        margin-left: 0;
        box-sizing: border-box;
        padding: 20px 10px;
        label {
          font-size: 15px;
        }
      }
      .ajouter-panier {
        &::before {
          display: none;
        }
      }
      .prix {
        width: 100%;
      }
      .ajouter-panier {
        box-sizing: border-box;
        margin-left: 0;
      }
      .declinaisons-achat .pro-pannier .ajouter-panier .addbasket {
        width: 100%;
      }

      .container-etiquette {
        margin-bottom: 50px;
      }
      .pro-pannier {
        width: 100%;
        margin: 0;
      }
      .declinaison {
        padding: 0;
      }
    }
    .content-tab {
      overflow: hidden;
    }
    .remise-produit {
      position: relative;
      right: auto;
      font-size: 30px;
      padding: 5px 15px;
      display: inline-block;
      height: auto;
      width: auto;
      border-radius: 0;
    }
    .bx-wrapper .bx-controls-direction a {
      top: 60px;
      &.bx-prev {
        top: 130px;
      }
    }
    .top_fiche.is_catalogue {
      .ajouter-panier {
        display: none;
      }
    }
    .produit_etiquette::before {
      left: -100%;
      padding: 0 10px 0 35px;
      right: auto;
    }
    .produit_etiquette:hover::before, .produit_etiquette:focus::before {
      left: 50%;
      right: auto;
      opacity: 1;
    }
  }
}

.template_fiche_produit_2 {

  .reservation__date .datepicker__month {
    width: 100%;
  }

  .datepicker__months:before {
    background: #dcdcdc;
    bottom: 0;
    content: '';
    display: none;
    left: 50%;
    position: absolute;
    top: 0;
    width: 1px
  }

  .datepicker__months {
    max-height: none;
  }

  .datepicker--submit .datepicker__months{
    max-height: 0;
  }

}

.modal {
  z-index: 2041;
}

.modal-backdrop {
  z-index: 2040;
}

.reservation__list > * {
  flex: 1 1 auto;
}



/*********************************************************/
/********************* GALERIE PHOTO *********************/
/*********************************************************/

.template_album.album-photo .list-photos .galerie {
  overflow: hidden;
  position: relative;
  .addcommentaires {
    border-width: 0;
    border-radius: 0;
    left: auto;
    top: 0;
    right: 100%;
    display: block;
    transition: all .2s linear;
  }

  .commentP, &:hover .commentP, &:focus .commentP {
    display: block;
    height: auto;
    position: absolute;
    bottom: auto;
    top: 0;
    p {
      margin-top: 35px;
      font-size: 16px;
      font-weight: bold;
      line-height: 16.3px;
      padding-bottom: 13px;
      display: block;
      text-transform: uppercase;
      text-align: left;
      position: absolute;
      transition: all .2s linear;
      bottom: auto;
      top: 0;
      left: 100%;
      max-width: 100%;
      box-sizing: border-box;
      padding-left: 10px;

      &:before {
        content: "";
        width: 18px;
        height: 2px;
        display: inline-block;
        position: absolute;
        bottom: 0;
      }
    }
  }
  &:hover, &:focus {
    box-shadow: none;
    transform: scaleX(1);
    .mask {
      opacity: 1;
    }
    .commentP p {
      left: 0;
    }
    .addcommentaires {
      right: 15px;
    }
  }
}

.template_album.album-photo.template_album_5 .list-photos .galerie .commentP,
.template_album.album-photo.template_album_4 .list-photos .galerie .commentP {
  padding: 15px 0;
  height: auto;
  position: relative;
  .mask {
    opacity: 1;
  }
  p {
    font-size: 16px;
    font-weight: bold;
    line-height: 16.3px;
    display: block;
    text-transform: uppercase;
    text-align: left;
    bottom: auto;
    padding: 10px 25px;
    margin-top: 0;
    position: relative;
    left: 0;

    &:empty {
      display: none;
    }

    &:before {
      content: "";
      width: 18px;
      height: 2px;
      display: inline-block;
      position: absolute;
      bottom: 0;
    }
  }
}

/**************************************************/
/********************* SLIDER *********************/
/**************************************************/

.bx-wrapper .bx-viewport {
  left: 0;
}

.banner_diaporama .bx-controls.bx-has-controls-direction {
  top: 0;
}

.banner_diaporama .slider-see-more {
  float: none;
}

body .banner_diaporama, body {
  .banner_diaporama .bx-controls.bx-has-controls-direction {
    top: 0;
  }
  .bx-wrapper .bx-controls-direction a {
    position: absolute;
    right: 30px;
    left: auto;
    top: 30px;
    bottom: auto;
    outline: 0;
    width: 60px;
    height: 60px;
    text-align: center;
    text-indent: -9999px;
    line-height: 60px;
    z-index: 50;
    transition: all .2s linear;
    margin-top: 0;
    &.bx-next::before, &.bx-prev::before {
      content: '\f105';
      display: block;
      text-indent: 0;
      font-family: Fontawesome, sans-serif;
    }
    &.bx-prev, &.bx-next {
      overflow: hidden;
    }
    &.bx-prev {
      top: 100px;
      &::before {
        content: '\f104';
      }
    }
  }
}

.accueil_diaporama_template {
  padding-bottom: 20px;
  .slide-text .content-slide-text > a {
    position: absolute;
    right: 30px;
    left: auto;
    top: 30px;
    bottom: auto;
    outline: 0;
    width: 60px;
    height: 60px;
    text-align: center;
    text-indent: -9999px;
    line-height: 60px;
    z-index: 50;
    transition: all .2s linear;
    margin-top: 0;
    &.addcommentaires {
      top: 170px;
      padding: 0;
      text-indent: 0;
      line-height: 60px;
    }
    &.slider-see-more {
      text-indent: 0;
      right: 90px;
      width: auto;
      line-height: initial;
      padding: 19px 10px;
      min-height: 60px;
      height: auto;
    }
  }
  .slide {
    > li, > div {
      position: relative;
      .center-img-slider {
        margin: 0;
        font-size: 0;
        line-height: 0;
      }
      .slide-text {
        font-size: 0;
        line-height: 0;
        .addcommentaires {
          font-size: 16px;
        }
      }
    }
    .slide-text .content-slide-text {
      > p {
        left: auto;
        right: 0;
        bottom: 0;
        margin: 0;
        padding: 30px;
        display: inline-block;
        font-size: 23px;
        line-height: 1.1;
        text-transform: uppercase;
      }
      > a.slider-see-more {
        bottom: auto;
      }
    }
  }

  &.accueil_diaporama_template_1 {
    .slide {
      max-width: 100%;
    }
  }

  &.accueil_diaporama_template_2 {
    padding-bottom: 10px !important;
    margin-bottom: 0;
  }

  &.accueil_diaporama_template_4,
  &.accueil_diaporama_template_6 {
    margin-top: 0;
    .slide .slide-text {
      .content-slide-text > a {
        &.slider-see-more {
          right: 30px;
          margin: 0;
          bottom: auto;
        }
        &.addcommentaires {
          top: 100px;
          //padding: 0;
          text-indent: 0;
        }
      }
    }
  }

  &.accueil_diaporama_template_5 .slide .slide-text .content-slide-text {
    > p {
      padding: 10px;
      left: 0;
      right: 0;
      text-transform: none;
      font-size: 16px;
      line-height: 20px;
    }
    .addcommentaires {
      top: 10px;
      right: 10px;
      bottom: auto;
      left: auto;
    }
    a.slider-see-more {
      right: auto;
      left: 10px;
      top: 10px;
      min-height: 0;
      margin-left: 0;
    }
  }
}

body .accueil_diaporama_template_1 .bx-wrapper, body .accueil_diaporama_template_2 .bx-wrapper, body .accueil_diaporama_template_3 .bx-wrapper {
  padding-bottom: 35px;
  margin-bottom: 0;
  .bx-viewport {
    max-width: 100%;
  }
  .bx-pager {
    bottom: 15px;
  }
}

/******************************************************/
/********************* LIVRE D'OR *********************/
/******************************************************/

.template_livreor_1 .message_lo {
  padding: 20px;
  border: 1px solid;
}

.button.addmsglo {
  margin: 10px auto;
  width: 50%;
  display: block;
  text-align: center;
}

/******************************************************/
/********************** CONTACT ***********************/
/******************************************************/

.template_contact_3 .row form .form-group textarea,
.template_contact_3 .row form .form-group input:not([type='file']) {
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

/**************************************************/
/********************* FOOTER *********************/
/**************************************************/

footer {
  padding: 60px 20px 20px;
  text-align: center;
  clear: both;
}

.fleche_top_site {
  display: none;
  width: 82px;
  height: 82px;
  border-radius: 50%;
  position: absolute;
  top: -41px;
  left: 50%;
  transform: translate(-50%, 0);
  font-size: 30px;
  line-height: 50px;
  text-align: center;
  &::before {
    content: '\f01b';
    font-family: fontAwesome, sans-serif;
  }
}

/* Autre */
#minisite-sidebar {
  z-index: 1002 !important;
}

/* Template Panier */
body.cmonsite-panier-2 #nav-principal > ul {
  float: none;
  padding-right: 0;
  max-width: 100%;
  + .template-panier.template-panier-2 {
    height: 64px;
    float: none;
    width: 100%;
    position: relative;
    box-sizing: border-box;
    border-left: 62px solid transparent;
    padding: 0 0 0 0;
    margin-top: -1px;
    top: 0;
    a {
      min-height: 0;
    }
    #panier {
      position: relative;
      top: 0;
      left: 0;
      padding-left: 25px;
      padding-right: 20px;
      .paniertoggle .fa {
        transform: rotate(-90deg);
      }
    }
    #panier #paniercontent {
      left: 100%;
      top: auto;
      bottom: -1px;
    }
    #panier #header-panier {
      box-shadow: none;
      padding: 20px 0;
      height: auto;
      line-height: initial;
      box-sizing: border-box;
      display: block;
      font-size: 16px;
      font-family: inherit;
      font-weight: 400;
      position: static;
      width: 100%;
      top: 0;
      text-align: left;
      > i {
        height: 62px;
        line-height: 62px;
        width: 62px;
        position: absolute;
        left: -62px;
        top: 0;
        text-align: center;
        font-size: 25px;
        z-index: 3;
      }
      .count-live {
        top: auto;
        margin-top: 0;
        margin-left: 0;
        left: -25px;
        z-index: 5;
      }
    }
  }
}

body.cmonsite-panier-2 .ferme #nav-principal > ul {
  + .template-panier.template-panier-2 {
    width: 61px;
    border-left: 0;
    &:hover {
      overflow: visible;
    }
    #panier #header-panier {
      font-size: 0;
      > i {
        left: 0;
      }
      .count-live {
        left: 35px;
        z-index: 4;
      }
    }
  }
}

/*-----------------------------
    FLUX PANIER
-------------------------------*/

#flux-panier.template_flux_panier.template_flux_panier_1 {
  .side-tabs.etapes,
  div#resume .block-recap-panier,
  div#resume div.next-step,
  div#information #connexion-inscription,
  div#information div.next-step,
  #infoscommande,
  div#finish .block-info-payment,
  div#finish div.next-step,
  div#resume h4,
  div#finish h4,
  .message-commande {
    width: 95%;
  }

  h4 {
    margin: 0;
  }

  #connexion-inscription > .col.s6 {
    padding: 0;
    width: 100%;
    margin: 15px 0;
  }

  div#information .list_address {
    margin: 0 0 20px;
  }
}

/*-----------------------------
    Boutons profil
-------------------------------*/

#order .commandes td {
  padding: 10px 0;
  border-top: 1px solid rgba(128, 128, 128, .1);
}

#order .commandes-details {
  > td > div {
    border-bottom: none;
  }
  .infosclient.s6 {
    &, &:first-child {
      width: 100%;
      margin: 10px 0 0;
    }
  }
}

/*********************************************************/
/********************* MEDIA QUERIES *********************/
/*********************************************************/

@media screen and (max-width: $largeur_fenetre_grand) {
  $largeur_fenetre_grand: $xlarge-screen - 80px;
  //$largeur_contenu: $largeur_fenetre_grand - 310px;

  #main-conteneur {
    width: $largeur_fenetre_grand;
  }
  .theme .wrapper-content, footer {
    width: $largeur_contenu;
  }
}

@media #{$xlarge-and-down} {
  $largeur_fenetre_grand: $large-screen - 80px;
  $largeur_contenu: $largeur_fenetre_grand - 310px;

  #main-conteneur {
    width: $largeur_fenetre_grand;
  }
  .theme .wrapper-content, footer {
    width: $largeur_contenu;
  }
  /* fiche produit */
  #fiche-produit .wrap-images .previews .list-previews {
    width: 69%;
  }
  //Template fiche produit
  .template_fiche_produit {
    #fiche-produit {
      .wrap-description {
        .block-price {
          flex: 1 1 100%;
          text-align: center;
        }
        .block-addbasket {
          flex: 1 1 100%;
          .addbasket {
            float: none;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $large-screen) and (min-width: $small-screen) {
  /* Menu */
  #headercontent, .menu, header.header_p {
    transition: width .5s ease 0s;
    transform-origin: center center;
  }
  #headercontent {
    position: relative;
    //overflow:hidden;
  }
  header.header_p.ferme {
    width: 62px;
  }
  .template-panier-2 {
    #header-panier .paniertoggle {
      box-sizing: content-box;
      margin-left: -62px !important;
      padding-left: 62px !important;
      z-index: 5;
    }
    #panier #paniercontent {
      left: 62px !important;
      right: auto !important;
      width: 498px !important;
      max-width: 498px !important;
    }
  }
  .cmonsite-panier-3 #main-conteneur #blocContent .template-panier.template-panier-3 .bloc-connexion-panier #panier #paniercontent {
    left: auto !important;
    right: -13px !important;
  }
  .close-menu {
    width: 42px;
    height: 42px;
    position: absolute;
    left: 100%;
    margin-bottom: 10px;
    margin-top: 10px;
    text-align: center;
    font-size: 26px;
    line-height: 42px;
    cursor: pointer;
    transition: all .5s ease 0s;

    &.ferme {
      position: relative;
      left: 10px;

      ~ #blocContent #headercontent {
        height: 0 !important;
        width: 0;
        overflow: hidden;
      }
      &::before {
        content: '\f0c9';
        font-family: fontAwesome, sans-serif;
      }
      ~ .menu {
        #nav-principal {
          > ul > li {
            width: auto;
            overflow: hidden;
            height: 64px;
            li:first-child {
              margin-top: 1px;
            }
            li li:first-child {
              margin-top: 0;
            }
            > a {
              width: 310px;
              &::before {
                line-height: 63px;

              }
            }
            &:hover, &:focus, > a:hover, > a:focus, &:hover a, &:focus a {
              width: 310px;
              overflow: visible;
              height: auto;
              a {
                width: 310px;
              }
            }
            ul {
              top: 100%;
              left: 62px;
              max-width: 248px;
              margin-top: -1px;
              ul {
                top: 0;
                left: 100%;
              }
            }
          }
          > ul + .template-panier.template-panier-2 {
            margin-top: 0;
            #panier #header-panier {
              font-size: 0 !important;
              .paniertoggle {
                margin-left: 0 !important;
              }
              > i {
                width: 61px !important;
                text-indent: 0 !important;
              }
            }
          }
        }
      }
    }
    &.ouvert {
      ~ .menu {
        #nav-principal {
          > ul > li {
            a {
              text-indent: 0;
            }
          }
          > ul + .template-panier.template-panier-2 {
            margin-top: 0;
            #panier #header-panier {
              text-indent: 0 !important;
              > i {
                text-indent: 0 !important;
              }
            }
            #panier #paniercontent {
              left: 0 !important;
            }
          }
        }
      }
      &::before {
        content: '\f00d';
        font-family: fontAwesome, sans-serif;
      }
    }
  }
}

@media #{$large-and-down} {
  $largeur_fenetre_grand: $medium-screen - 80px;
  $largeur_contenu: $largeur_fenetre_grand - 62px;

  #main-conteneur {
    width: $largeur_fenetre_grand;
  }
  .theme .wrapper-content, footer {
    width: $largeur_contenu;
  }
  /* Fiche produit */
  #fiche-produit .wrap-images .previews .list-previews {
    width: 64%;
  }

  //Template fiche produit
  .template_fiche_produit {
    #fiche-produit {
      .wrap-description {
        .block-price {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }
}

@media #{$medium-and-down} {
  $largeur_fenetre_grand: $small-screen - 80px;
  $largeur_contenu: $largeur_fenetre_grand - 62px;

  #main-conteneur {
    width: $largeur_fenetre_grand;
  }
  .theme .wrapper-content, footer {
    width: $largeur_contenu;
  }
  /*Suppression du bloc de recherche à droite*/
  #block-flottant-search {
    display: none;
  }
  /* Passage des deux colonnes en une */
  .sidebar, section.col-md-10 {
    float: none;
    padding: 0;
  }
  .sidebar {
    width: 100%;
    padding: 20px;
    li a::before {
      content: '- ';
      margin: 0 5px;
      display: inline-block;
    }
  }
  section.col-md-10 {
    width: 100%;
  }
  /* Fiche produit */
  #fiche-produit .wrap-images .previews .list-previews {
    width: 49%;
  }

  .template_fiche_produit #fiche-produit .top_fiche .wrap-description .pro-pannier ~ p:not(.details-ficheprod) {
    width: 100%;
  }

  .template_boutique_accueil {
    &.accueil_boutique_template_1 {
      $largeurFleches: 45px;
      $margin: 20px;
      $nbrProduit: 2;
      @include produitAccueilTaille($largeurFleches, $nbrProduit, $margin);
    }
  }

  .template_fiche_produit {
    &.template_fiche_produit_1 #fiche-produit {
      .top_fiche {
        .wrap-images, .wrap-description {
          width: 100%;
        }
      }
    }
  }

}

@media #{$medium-and-up}{
  .menu_burger {
    display: none;
  }
  .button_galerie {
    display: none;
  }
}

@media #{$small-and-down} {

  #bloc_cookies p, #bloc_cookies .bloc_boutons_cookies {
    font-size: .8rem;
    text-align: center;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  body.cmonsite-panier-2 #nav-principal > ul {
    + .template-panier.template-panier-2 {
      width: 100% !important;
      height: 0 !important;
      border-left: 0;
      top: 72px !important;
      right: 0;
      padding: 0;
      overflow: visible;
      position: absolute !important;
      #panier {
        padding: 0;
      }
      #panier #header-panier {
        font-size: 0;
        padding: 0;
        height: 62px !important;
        width: 62px !important;
        right: 84px !important;
        left: auto !important;
        top: -61px !important;
        bottom: auto !important;
        position: absolute !important;
        .paniertoggle {
          z-index: 10;
        }
        > i {
          left: 0;
          height: 62px;
          text-indent: 0 !important;
        }
        .count-live {
          left: 35px;
          z-index: 4;
          top: 17px;
        }
      }
      #panier #paniercontent {
        left: 0;
        bottom: auto !important;
      }
    }
  }

  .cmonsite-panier-3 .slogan {
    padding-bottom: 0;
  }

  .cmonsite-panier-3 #main-conteneur #blocContent .template-panier-3 .bloc-connexion-panier #panier #paniercontent {
    left: -13px;
  }
  .template-panier-3 {

    .connexion {
      width: 50%;

      p {
        border-right: 1px solid;
      }
    }

    #header-panier {
      text-align: left;
      padding-left: 20px;
    }

    #panier {
      width: 50%;
    }

    .count-live {
      margin-left: 0;
      left: 30px;
      top: 22px;
    }
  }
  /* Met une position par défaut du logo et du slogan */
  .slogan {
    position: relative !important;
    left: 0 !important;
    top: 0 !important;
    margin-top: 15px;
    padding-bottom: 15px;
    text-align: center !important;
    font-size: 15px !important;
    display: block;
    width: 100% !important;
  }
  #logo {
    position: relative !important;
    width: calc(100% - 84px) !important;
    text-align: center !important;
    left: 0 !important;
    top: 0 !important;
    margin-top: 10px;
    display: block;
    height: 80px;
    img {
      width: auto !important;
      max-height: 70px;
      float: left;
      margin: 0 20px;
    }
  }
  section.col-md-10 {
    margin-top: 20px;
  }
  #main-conteneur {
    width: 95%;
  }
  .theme .wrapper-content, footer {
    width: 100%;
  }
  header {
    position: relative;
    width: 100%;
  }
  #headercontent {
    width: 100%;
    height: auto !important;
    position: relative;
  }

  /* Tableaux responsive */
  @import '../../design/helper/responsive/mobile/mobile';

  /* Menu responsive */
  .cmonsite-panier-2 #nav-principal > .menu_burger {
    padding-right: 0 !important;
  }
  #nav-principal {
    width: 100%;
    padding: 0;
    position: static;
    > ul {
      width: 100%;
      position: relative;
      display: none;
      ul {
        position: relative;
        top: 0;
        left: 0 !important;
        max-width: 100%;
        transform-origin: left center;
        transform: scaleX(1) !important;
        transition-property: none;

        ul {
          box-shadow: 0 0 5px 0 rgba(0, 0, 0, .1);
        }

        li {
          border-right-width: 0;
          border-left-width: 0;
          &:last-child {
            border-bottom-width: 0;
          }
        }
      }
    }
    li {
      width: 100%;
      display: block;
      border-left: 1px solid;
      &.sousmenu_ouvert > a::after {
        left: 0 !important;
        top: 98%;
        margin-left: -36px;
        transform: rotate(90deg);
        opacity: 1 !important;
      }
    }
    ul > li {
      > a {
        position: relative;
        &:before, > span > i {
          left: -62px !important;
        }
        &:after {
          left: -5px;
        }
      }
      &:hover > a::after, &.actif > a::after {
        left: 0;
      }
    }
  }
  #nav-principal a.menu_burger {
    text-align: center;
    position: absolute;
    width: 62px;
    height: 62px;
    line-height: 62px;
    right: 10px;
    top: 10px;
    font-size: 25px;
    display: inline-block;
    padding: 0;
    &::before {
      content: '\f0c9';
      display: inline-block;
      font-family: 'FontAwesome', sans-serif;
    }
  }

  #minisite-sidebar {
    display: none;
  }

  /* Produits */
  .produit_etiquette {
    overflow: visible;
    &::before {
      opacity: 1;
      right: 25px;
    }
  }
  .template_boutique_all {
    .produits {
      .productImageWrap > .zoom, &:hover .productImageWrap > .zoom, .mask {
        display: block !important;
        top: 0 !important;
        left: 0 !important;
        opacity: 1 !important;
        bottom: auto !important;
        height: 70px !important;
      }
      .zoom, .addbasket .inside-addbasket, .hors-stock .inside-hors-stock {
        margin: 0 !important;
        width: 70px !important;
        height: 70px !important;
        opacity: 1 !important;
        &::before, i {
          line-height: 70px !important;
          height: 70px !important;
          width: 70px !important;
          text-align: center;
          font-size: 20px !important;
        }
      }
      .addbasket .inside-addbasket, .hors-stock .inside-hors-stock {
        top: 80px !important;
        left: 0 !important;
      }
      .addbasket, .hors-stock {
        left: 0 !important;
        padding: 0 !important;
        margin: 0 !important;
      }
      .prix {
        width: 100% !important;
        border-right-color: transparent !important;
      }
      .remise {
        position: absolute !important;
        left: 80px !important;
        top: 0 !important;
        z-index: 2;
        width: auto;
        padding: 0 15px;
        right: auto;
        display: inline-block;
      }
    }
    &.template_boutique_3 .produits {
      .remise {
        left: 90px !important;
        top: 10px !important;
      }
      .addbasket .inside-addbasket, .hors-stock .inside-hors-stock {
        top: 90px !important;
        left: 10px !important;
      }
    }
    &.template_boutique_5 .produits {
      .remise {
        left: 90px !important;
        top: 10px !important;
      }
      .container-etiquette .produit_etiquette {
        position: absolute !important;
        &::before {
          padding: 0 35px 0 10px !important;
          right: 25px !important;
          left: auto !important;
        }
      }
    }
  }

  body #wrapper .template_boutique.template_boutique_4 .produits.block_unique_product .remise {
    position: absolute !important;
    right: 5px !important;
    width: auto !important;
    height: 55px !important;
    line-height: 55px !important;
    padding: 0 10px !important;
    text-align: center !important;
    z-index: 1 !important;
    top: 60px !important;
    left: auto !important;
  }

  .produits .remise ~ .prix, .produits .remise ~ .productContentWrap .prix {
    width: 50%;
    border-right: 1px solid;
    height: 55px;
    line-height: normal;
    padding: 5px;
    margin: 0 0 20px !important;
  }

  /* Fiche produit */
  #fiche-produit .top_fiche .wrap-description {
    width: 100%;
  }
  .template_fiche_produit.template_fiche_produit_3 #fiche-produit .produit_etiquette::before {
    left: 22px;
  }

  .template_fiche_produit #fiche-produit .top_fiche .wrap-description {

    .ajouter-panier {
      text-align: center;

      .remise-produit {
        font-size: 1.2rem;
      }

      .prix .prixprod {
        font-size: 1.3rem;
      }

      .addbasket {
        margin: 15px auto 0;
        float: none;
      }
    }

    .details-ficheprod {
      float: none;
      margin: 17px auto;
    }
  }

  /* Slider */
  .template_boutique_accueil {
    &.accueil_boutique_template_1 {
      $largeurFleches: 45px;
      $margin: 0px;
      $nbrProduit: 1;
      @include produitAccueilTaille($largeurFleches, $nbrProduit, $margin);
      .bx-wrapper .bx-controls-direction a.bx-prev {
        left: auto !important;
        right: -45px !important;
      }
    }
  }

  /* Galerie */
  .button_galerie {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 9999;
    width: 45px;
    text-align: center;
  }
  .template_album.album-photo .list-photos .galerie {
    margin-bottom: 15px;
    .bloc-cache-overflow {
      overflow: hidden !important;
      max-height: 0 !important;
      padding: 0 !important;
      height: 100% !important;
      position: absolute !important;
      top: 0 !important;
    }
    &:hover {
      .bloc-cache-overflow {
        min-height: 0 !important;
      }
      .addcommentaires {
        right: 100% !important;
      }
      .mask {
        opacity: 0 !important;
      }
    }
    &.hover {
      .mask {
        max-height: 100% !important;
        opacity: 1 !important;
      }
      .bloc-cache-overflow {
        max-height: 100% !important;
        > p {
          margin-top: 35px !important;
        }
      }
      .addcommentaires {
        right: 15px !important;
      }
      .bloc-titre-galerie p {
        left: 0 !important;
        font-size: 16px;
        font-weight: bold;
        line-height: 16.3px;
        padding-bottom: 13px;
        display: block;
        text-transform: uppercase;
        text-align: left;
        position: absolute;
        transition: all .2s linear;
        bottom: auto;
        top: 0;
      }
    }
  }
  .template_album_3 .list-photos .galerie .mask {
    max-height: 0 !important;
  }

  .accueil_boutique_template_1 .bx-wrapper .bx-controls-direction a {
    right: 0;
  }
  .cmonsite-panier-3 #main-conteneur #blocContent {
    padding-bottom: 10px;
  }
  body.cmonsite-panier-3 #main-conteneur #blocContent #headercontent .template-panier.template-panier-3 {
    position: relative;
    height: auto;
    margin: 10px auto;
  }
}

/* Menu responsive Général */
.dropDown {
  position: absolute;
  right: 0;
  top: 0;
  font-weight: bold;
  display: block;
  height: 62px;
  width: 60px;
  line-height: 62px;
  cursor: pointer;
  text-align: center;
  font-size: 1.2rem;
  &::before {
    font-family: FontAwesome, sans-serif;
    content: "\f0d7";
  }
}

li.sousmenu_ouvert > .dropDown::before {
  content: '\f0d8';
}

.menu-protection-droite {
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, .1);
}

.template_boutique.template_boutique_all {
  &.template_boutique_1, &.template_boutique_2, &.template_boutique_5 {

    .product_have_promotion {
      > .remise {
        display: none !important;
      }

      > .productContentWrap {

        .prix {
          width: 100% !important;
          .prix__block {
            width: 50% !important;
            display: inline-block !important;
            float: left !important;
          }
        }

        .remise {
          width: 50% !important;
          display: inline-block !important;
          position: relative !important;
          margin: 0 !important;
          top: -6px !important;
          bottom: 0 !important;
          left: 6px !important;
          right: -8px !important;
          float: left !important;
        }
      }

    }
  }
  &.template_boutique_3 .produits {
    .addbasket, .hors-stock {
      .inside-addbasket {
        top: unset !important;
        left: unset !important;
        right: unset !important;
        bottom: unset !important;
      }
    }

  }
}
